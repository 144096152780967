import instance from "../../../../services/api";

export const fetchGpr = (remont_id) => {
  return instance(true)
    .get(`/partner/remont/${remont_id}/gpr/`)
    .then((res = {}) => res?.data);
};
export const fetchSmetaMaterials = (remont_id) => {
  return instance(true)
    .get(`/partner/remont/${remont_id}/smeta_materials/`)
    .then((res = {}) => res?.data);
};
export const fetchTextConstructor = (remont_id) => {
  return instance(true)
    .get(`/partner/remont/${remont_id}/text_constructor/`)
    .then((res = {}) => res?.data);
};

export const addProviderInfo = (remont_id, params) => {
  return instance(true)
    .post(`/partner/remont/${remont_id}/provider_info/`, params)
    .then((res = {}) => {
      return res;
    });
};
export const fetchRemontInfo = (remont_id) => {
  return instance(true)
    .get(`/partner/remont/${remont_id}/`)
    .then((res = {}) => {
      return res;
    });
};
export const getRemontTabulations = () => {
  return instance(true)
    .get(`/partner/remont/tabulations/`)
    .then((res = {}) => res?.data);
};

export const getProrabList = () => {
  return instance(true).get(`/partner/prorab/`);
};

export const getTechFeature = (remont_id) => {
  return instance(true).get(`/partner/remont/${remont_id}/tech_feature/`);
};

export const getProjectManagers = () => {
  return instance(true).get(`/partner/project_managers/`);
};
export const changeRemontContractor = (remont_id, body) => {
  return instance(true)
    .put(`/partner/remont/${remont_id}/contractor/`, body)
    .then((res) => res?.data);
};
export const changeRemontGeneralContractor = (remont_id, body) => {
  return instance(true)
    .put(`/partner/remont/${remont_id}/general_contractor/`, body)
    .then((res) => res?.data);
};

export const acceptRemont = (remont_id, params) => {
  return instance(true).post(
    `/partner/remont/${remont_id}/contractor_status/`,
    params
  );
};
